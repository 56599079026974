import React from 'react';

const Location = () => (
  <div className='footer-block'>
    <h2>LOCATION</h2>
    <p>736 West 16th Ave.</p>
    <p>Vancouver, BC Canada</p>
  </div>
)

export default Location;