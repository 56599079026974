import React from 'react';

const Map = () => {
  return (
    <div id='map'>
      <iframe src="https://snazzymaps.com/embed/111616" width="100%" height="450px" style={{border:'none'}}></iframe>
    </div>
  )
}

export default Map;